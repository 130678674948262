$font-size: 50px;
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-phone-number-input/style.css';

@font-face {
  font-family: 'Avenir';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/Avenir-Roman-400.woff2') format('woff2');
}
@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/Avenir-Medium-500.woff2') format('woff2');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/Avenir-Heavy-800.woff2') format('woff2');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/Avenir-Black-900.woff2') format('woff2');
}

// FONT BRANDON
@font-face {
  font-family: 'brandon';
  src:
    url('/static/fonts/brandontext-regular-webfont.woff2') format('woff2'),
    url('/static/fonts/brandontext-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'brandon';
  src:
    url('/static/fonts/brandontext-medium-webfont.woff2') format('woff2'),
    url('/static/fonts/brandontext-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'brandon';
  src:
    url('/static/fonts/brandontext-bold-webfont.woff2') format('woff2'),
    url('/static/fonts/brandontext-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_600';
  src:
    url('/static/fonts/robotocondensed-bold-webfont.woff2') format('woff2'),
    url('/static/fonts/robotocondensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_400';
  src:
    url('/static/fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
    url('/static/fonts/robotocondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// FONT MULI
@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/static/fonts/Muli/Muli-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

.example {
  font-size: $font-size;
}

body {
  @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700|Nunito:300,400,600,700|Open+Sans:300,400,600,700|Muli:400,600,700|Merriweather:400,600,700|Montserrat:400,600,700|Lato:400,600,700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;600&family=Poppins:wght@300;700&display=swap');

  background-color: #000;
  margin: 0 auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  * {
    &::placeholder {
      color: #757575;
      opacity: 1;
    }
  }

  /* Firefox: */

  @-moz-document url-prefix() {
    select {
      text-indent: -2px;
    }
  }
}

.lead-form,
.payment-form,
.code-form {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.buzz-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.logo {
  height: 134px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    width: 196px;
  }
}

.logo.logo-back-button {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;

  @media (min-width: 426px) {
    padding: 0 30px;
  }
}

h1 {
  color: white;
  font-weight: normal;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  font-size: 17px;
  line-height: normal;
}
.VideoWrapper,
.iframe-wrapper {
  margin: auto;
  max-width: 690px;
  iframe {
    max-width: 100%;
  }
}

select option {
  background-color: #fff;
  color: #616161;
  font-size: 15px;
  line-height: 21px;
  padding: 20px;
}

.payment-form {
  .seals-wrapper {
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
    margin: -20px auto 60px;

    .seal {
      img {
        width: 89px;
        &.sectigo {
          height: 29px;
        }
      }
    }
  }
}

.floatBtnText-icon-wpp-storage {
  right: 80px !important;

  @media (max-width: 767px) {
    bottom: 15px !important;
  }
}
.grecaptcha-badge {
  right: -186px !important;
}

.no-line-break-word {
  white-space: nowrap;
}
